import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    MeetingProvider,
    MeetingConsumer,
    useMeeting,
    useParticipant,
    usePubSub,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { BottomNavigation, BottomNavigationAction, Box, Menu, MenuItem, Paper, Typography, styled } from "@material-ui/core";
import { Mic, CallEnd, MicOff } from "@material-ui/icons";
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import VolumeUpOutlined from '@material-ui/icons/VolumeUpOutlined';
import VolumeOffOutlined from '@material-ui/icons/VolumeOffOutlined';
import { HexColorPicker } from "react-colorful";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { toast } from 'react-toastify';

const icDefaultAvatar = require("./default_avatar.png");
const icScreenSharing = require("./share_screen.png");
const icScreenRecording = require("./screen_recording.png");
const icScreenRecordingActive = require("./screen_recording_active.png");
const icScreenDrawing = require("./screen_drawing.png");
const icOverlay = require("./overlay.png");
const ic_square = require("./ic_square.png");
const ic_triangle = require("./ic_triangle.png");
const ic_freestyle = require("./ic_freestyle.png");
const ic_arrow = require("./ic_arrow.png");
const ic_down = require("./ic_down.png");
const ic_animation = require("./animation.png");
const ic_stop_button = require("./stop_button.png");
const BottomBarStyle = styled("div")({
    position: "absolute",
    left: "20px",
    height: "94px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    "@media(max-width:600px)": {
        display: "none"
    },

    "& p": {
        "@media(max-width:1050px)": {
            display: "none"
        }
    }
});

const BottomRightBarStyle = styled("div")({
    position: "absolute",
    right: "20px",
    height: "94px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    bottom: "0px",
    zIndex: 10,

    "@media(max-width:1050px)": {
        left: "10px",
        top: "80px",
        width: "75px",
        flexDirection: "column-reverse",
        height: "fit-content",

        "@media(max-width:1080px)": {
            top: "170px",
        },

        "@media(max-width:600px)": {
            top: "200px",
        },
    },

    "& p": {
        "@media(max-width:1050px)": {
            display: "none"
        }
    }
});

const ParticipantContainerStyle = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    position: "relative",
    flexDirection: "column",

    "& .participantTitle": {
        "@media(min-width: 600px) and (max-width: 1080px)": {
            width: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    "& .animationContainer": {
        position: "absolute",
        top: "10px",
        right: "10px",
        display: "flex",
        zIndex: 6,

        "@media(max-width:600px)": {
            top: "90px",
        },

        "& img": {
            width: "625px",
            aspectRatio: 1.78,
            maxWidth: "50vw",
            objectFit: "contain"

        }
    },
    "& .tiles": {
        width: "100%",
        backgroundColor: "rgba(128,128,128,0.8)",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "row",
        padding: "20px",
        gap: "35px",
        overflowX: "auto",
        overflowY: "hidden",
        maxWidth: "100%",
        scrollSnapType: "x mandatory",
        boxSizing: "border-box",
        zIndex: 6,

        "@media(max-width:600px)": {
            gap: "20px",
            top: "75px"
        },
        "& .tile:first-child": {
            marginLeft: "auto",
        },
        "& .tile:last-child": {
            marginRight: "auto",
        },

        "& .tile": {
            flexShrink: 0,
            width: "120px",
            height: "120px",
            display: "flex",
            padding: "10px 10px 5px 10px",
            backgroundColor: "#D7D7ED",
            borderRadius: "8px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            scrollSnapAlign: "center",

            "@media(max-width:600px)": {
                width: "85px",
                height: "85px",
                padding: "5px 5px 3px 5px",
            },

            "& .title": {
                color: "#323436",
                fontSize: "12px",
                fontWeight: 600,
                fontFamily: "source-sans-pro",
                textTransform: "uppercase",
            },
            "& .value": {
                color: "#1D795C",
                fontSize: "50px",
                fontWeight: 300,
                fontFamily: "source-sans-pro",

                "@media(max-width:600px)": {
                    fontSize: "36px",
                },
            },
            "& .unit": {
                color: "#323436",
                fontWeight: 600,
                fontSize: "12px",
                fontFamily: "source-sans-pro",
                textTransform: "uppercase",

                "@media(max-width:600px)": {
                    fontSize: "10px",
                },
            }
        }
    },

    "& .colorSliderContainer": {
        zIndex: 10,
        position: "absolute",
        top: "15px",
        left: `calc((100vw - 200px)/2)`,

        "@media(max-width:600px)": {
            top: "90px",
            left: "20px",
        },
    },

    "& .colorSlider": {
        height: "unset",

        "& .react-colorful__hue": {
            borderRadius: "8px",
            height: "12px",
        },
        "& .react-colorful__saturation": {
            display: "none"
        },
    },

    "& .screenDrawingContainer": {
        zIndex: 10,
        position: "absolute",
        right: 0,
        top: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(128,128,128,0.8)",
        gap: "10px",

        "@media(max-width:600px)": {
            top: "80px",
        },

        "& .drawBtn": {
            cursor: "pointer",
            padding: "20px",
            width: "60px",
            height: "60px",

            "@media(max-width:600px)": {
                width: "40px",
                height: "40px",
            },
        },

        "& .selected": {
            border: "1px solid white"
        }
    },

    "& .topMobileNav": {
        display: "none",

        "@media(max-width:600px)": {
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
        },

    },

    "& .containerFullScreen": {
        height: "100%",
        backgroundColor: "black",
        overflow: "hidden",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        position: "relative",
        borderRadius: 0,
    },
    "& .camContainerFullScreen": {
        position: "relative",
        overflow: "hidden",
        backgroundColor: "black",
        width: "100%",
        height: `calc(100vh - 94px)`,
        borderRadius: 0,
        objectFit: "cover",
    },
    "& .containerMiniRight": {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        maxWidth: "calc(min(50%, 330px))",
        width: "100%",
        maxHeight: "185px",
        aspectRatio: 330 / 185,
        right: "10px",
        bottom: "105px",
        borderRadius: 8,
        backgroundColor: "black",
        objectFit: "cover",
        zIndex: 10,

    },
    "& .containerMiniLeft": {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        width: "330px",
        height: "185px",
        left: "10px",
        bottom: "105px",
        borderRadius: 8,
        backgroundColor: "black",
        objectFit: "cover",
        zIndex: 10,
    },
    "& .camContainerMiniRight": {
        position: "relative",
        borderRadius: 8,
        overflow: "hidden",
        backgroundColor: "black",
        width: "100%",
        height: "100%",
        zIndex: 10
    },
    "& .drawingCanvas": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 5,
        pointerEvents: "none",

        "& > div": {
            border: "none !important"
        },
    },
    "& .drawingCanvasEnabled": {
        pointerEvents: "auto"
    }
});

function ParticipantView({ onVideoClick, isAudio, participantId, cameraStyle, containerStyle }: { onVideoClick?: () => void, participantId: string; cameraStyle?: string; containerStyle?: string; isAudio: boolean; }) {
    const micRef = useRef<HTMLAudioElement>(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName, screenShareOn, screenShareStream, screenShareAudioStream } =
        useParticipant(participantId);

    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);
                if (screenShareOn && screenShareAudioStream) {
                    mediaStream.addTrack(screenShareAudioStream.track);
                }
                micRef.current.srcObject = mediaStream;
                micRef.current
                    .play()
                    .catch((error) =>
                        console.error("videoElem.current.play() failed", error)
                    );
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn, screenShareOn, screenShareAudioStream]);

    const screenStream = useMemo(() => {
        if (screenShareStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareStream.track);
            return mediaStream;
        }
    }, [screenShareStream, screenShareOn]);

    return webcamOn || screenShareOn ? (
        <div key={participantId} className={containerStyle} style={{ cursor: onVideoClick ? "pointer" : "auto" }} onClick={onVideoClick}>
            <audio ref={micRef} autoPlay />
            {screenShareOn ?
                <ReactPlayer
                    key={'video-screenshare'}
                    playsinline
                    pip={false}
                    light={false}
                    controls={false}
                    muted={!isAudio}
                    playing={true}
                    volume={1}
                    url={screenStream}
                    width={"100%"}
                    height={"100%"}
                    className={cameraStyle}
                    onError={(err) => {
                        console.log(err, "participant video error");
                    }}
                ></ReactPlayer>
                : <ReactPlayer
                    key={'video-webcam'}
                    playsinline
                    pip={false}
                    light={false}
                    controls={false}
                    muted={!isAudio}
                    playing={true}
                    volume={1}
                    url={videoStream}
                    width={"100%"}
                    height={"100%"}
                    className={cameraStyle}
                    onError={(err) => {
                        console.log(err, "participant video error");
                    }}
                ></ReactPlayer>}
        </div>
    ) : <audio ref={micRef} autoPlay />
}

export function MeetingView({
    onMeetingLeave,
    meetingId,
    coachProfile,
    authToken,
    joinStatus = null
}: {
    onMeetingLeave: () => void;
    meetingId: string;
    coachProfile: any;
    authToken: string;
    joinStatus?: string | null;
}) {
    const [isAudio, setIsAudio] = useState(true);

    const [isScreenDrawingOpen, setIsScreenDrawingOpen] = useState(false);
    const [color, setColor] = useState("#00d5ff");
    const [selectedDrawing, setSelectedDrawing] = useState<string>('freestyle');
    const [time, setTime] = useState(0);
    const [recordingTime, setRecordingTime] = useState(0);

    const joinBtnRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const startCoords = useRef<{ x: number, y: number } | null>(null);

    const [selectedTiles, setSelectedTiles] = useState<{ title: string, value: string, unit: string }[]>([]);
    const [isOverlayVisible, setIsOverlayOpen] = useState(false);
    const [isTilesSelected, setIsTilesSelected] = useState(false);
    const [isAnimationVisible, setIsAnimationVisible] = useState(false);
    const anchorEl = useRef(null);

    const onSelectTile = (tile: { title: string, value: string, unit: string }) => () => {
        setSelectedTiles((prevSelectedTiles: { title: string, value: string, unit: string }[]) => {
            setIsScreenDrawingOpen(false);

            if (isAnimationVisible) {
                setIsAnimationVisible(false);
            }

            if (prevSelectedTiles.some(selectedTile => selectedTile.title === tile.title)) {
                return prevSelectedTiles.filter(selectedTile => selectedTile.title !== tile.title);
            } else {
                return [...prevSelectedTiles, tile];
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => prevTime + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (meetingId && authToken && joinBtnRef.current) {
            setTimeout(() => {
                joinBtnRef.current?.click();
            }, 1000)
        }
    }, [meetingId, authToken])

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const recordingFormat = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''
            }${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const [joined, setJoined] = useState<string | null>(joinStatus);
    const [isVideoSwap, setIsVideoSwap] = useState(false);
    const { join, toggleWebcam, toggleMic, leave, localWebcamOn, localMicOn } = useMeeting();
    const { participants, localParticipant, startRecording, isRecording, stopRecording, enableScreenShare, disableScreenShare } = useMeeting({
        onMeetingJoined: () => {
            setJoined("JOINED");
        },
        onMeetingLeft: () => {
            onMeetingLeave();
        },
    });
    const [isScreenSharing, setIsScreenSharing] = useState(false);

    useEffect(() => {
        let interval: any;
        if (isRecording) {
            interval = setInterval(() => {
                setRecordingTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            setRecordingTime(0);
        }

        return () => clearInterval(interval);
    }, [isRecording]);

    const onClickRecording = () => {
        if (isRecording) {
            stopRecording();
            toast.success("Record saved successfully")
        } else {
            startRecording();
        }
    }

    const joinMeeting = () => {
        setJoined("JOINING");
        join();
    };

    const participant = Array.from(participants?.values()).filter(user => user.id !== localParticipant?.id)[0];

    const onVideoSwap = () => {
        setIsVideoSwap(prestate => !prestate);
    }

    const onToggleScreenDrawing = () => {
        if (isScreenDrawingOpen == false) {
            publish(JSON.stringify({ isDrawing: true }), { persist: false });
        }
        else {
            publish(JSON.stringify({ isClear: true }), { persist: false })
        }
        setIsAnimationVisible(false);
        setSelectedTiles([]);
        setIsScreenDrawingOpen(prestate => !prestate);
        toggleScreenDrawingMode(JSON.stringify(!isScreenDrawingOpen), { persist: true });
        }

    const participantRender = useMemo(() => {
        if (isVideoSwap && participant) {
            return [localParticipant, participant].filter(Boolean);
        } else return [participant, localParticipant].filter(Boolean);
    }, [isVideoSwap, participant, localParticipant]);

    const canvasProps = {
        width: "100%",
        height: "100%",
        preserveBackgroundImageAspectRatio: "none",
        strokeWidth: 4,
        eraserWidth: 5,
        strokeColor: selectedDrawing === 'freestyle' ? color : "rgba(255, 255, 255, 0)",
        canvasColor: "rgba(0, 0, 0, 0)",
        allowOnlyPointerType: "all",
        withViewBox: false,
    };

    const { publish } = usePubSub("WHITEBOARD", {

        onMessageReceived: async (message:any) => {
            const webWidth = window.outerWidth;
            const webHeight = window.outerHeight;
            let data = JSON.parse(message?.message)
             if (data?.type == "arrowLinePath") {
                const normalizeCoordinatesToWeb = (xMobile:any, yMobile:any, webWidth:any, webHeight:any) => {
                    const mobileWidth = JSON.parse(message?.message)?.mobileWidth;
                    const mobileHeight = JSON.parse(message?.message)?.mobileHeight;
            
                    const xWeb = (xMobile / mobileWidth) * webWidth;
                    const yWeb = (yMobile / mobileHeight) * webHeight;
            
                    return { x: xWeb, y: yWeb };
                };
            
                const normalizePathsToWeb = (paths:any) => {
                    return paths?.map((path:any) => {
                        const { x, y, ...rest } = path;
                        const normalized = normalizeCoordinatesToWeb(x, y, webWidth, webHeight);
                        return { ...rest, ...normalized };
                    });
                };
            
                const generateArrowPath = (startX:any, startY:any, endX:any, endY:any, headLength = 15) => {
                    const dx = endX - startX;
                    const dy = endY - startY;
                    const angle = Math.atan2(dy, dx);
                    const lineLength = Math.sqrt(dx * dx + dy * dy);
            
                    const unitDx = dx / lineLength;
                    const unitDy = dy / lineLength;
            
                    const adjustedEndX = endX - unitDx * headLength;
                    const adjustedEndY = endY - unitDy * headLength;
            
                    const arrowHeadLeft = {
                        x: adjustedEndX - headLength * Math.cos(angle - Math.PI / 6),
                        y: adjustedEndY - headLength * Math.sin(angle - Math.PI / 6),
                        isL: true,
                    };
            
                    const arrowHeadRight = {
                        x: adjustedEndX - headLength * Math.cos(angle + Math.PI / 6),
                        y: adjustedEndY - headLength * Math.sin(angle + Math.PI / 6),
                        isL: true,
                    };
            
                    return [
                        { x: startX, y: startY, isL: true },
                        { x: adjustedEndX, y: adjustedEndY, isL: true },
                        arrowHeadLeft,
                        { x: adjustedEndX, y: adjustedEndY, isL: true },
                        arrowHeadRight,
                    ];
                };
            
                let normalizedPaths = await normalizePathsToWeb(data?.paths);
            
                if (normalizedPaths?.length >= 2) {
                    const startX = normalizedPaths[0].x;
                    const startY = normalizedPaths[0].y;
                    const endX = normalizedPaths[1].x;
                    const endY = normalizedPaths[1].y;
            
                    const arrowPaths = generateArrowPath(startX, startY, endX, endY);
                    data.paths = arrowPaths;
                } else {
                    data.paths = normalizedPaths;
                }
            }
            else if(data?.type == "trianglePath"){

                const normalizeCoordinatesToWeb = (xMobile: any, yMobile: any, webWidth: any, webHeight: any) => {
                    let mobileWidth = JSON.parse(message?.message)?.mobileWidth;
                    let mobileHeight = JSON.parse(message?.message)?.mobileHeight;
                    
                    const xWeb = (xMobile / mobileWidth) * webWidth;
                    const yWeb = (yMobile / mobileHeight) * webHeight;

                    return { x: xWeb, y: yWeb };
                };
                const normalizePathsToWeb = (paths: any) => {
                    return paths?.map((path: any) => {
                        const { x, y, ...rest } = path;
                        const normalized = normalizeCoordinatesToWeb(x, y, webWidth, webHeight);
                        return { ...rest, ...normalized };
                    });
                };
                let paths = await normalizePathsToWeb(data?.paths)
                data.paths = paths;
            }
            else if(data?.type == "rectanglePath"){

                const normalizeCoordinatesToWeb = (xMobile: any, yMobile: any, webWidth: any, webHeight: any) => {
                    let mobileWidth = JSON.parse(message?.message)?.mobileWidth;
                    let mobileHeight = JSON.parse(message?.message)?.mobileHeight;
                    
                    const xWeb = (xMobile / mobileWidth) * webWidth;
                    const yWeb = (yMobile / mobileHeight) * webHeight;

                    return { x: xWeb, y: yWeb };
                };
                const normalizePathsToWeb = (paths: any) => {
                    return paths?.map((path: any) => {
                        const { x, y, ...rest } = path;
                        const normalized = normalizeCoordinatesToWeb(x, y, webWidth, webHeight);
                        return { ...rest, ...normalized };
                    });
                };
                let paths = await normalizePathsToWeb(data?.paths)

                let sideLength = 100;
                const midX = (paths[0]?.x + paths[0]?.x+20) / 2;
                const midY = (paths[0]?.y + paths[0]?.y+20) / 2;
        
                paths = [
                    { x: midX - sideLength / 2, y: midY - sideLength / 2, isL: true },
                    { x: midX + sideLength / 2, y: midY - sideLength / 2, isL: true },
                    { x: midX + sideLength / 2, y: midY + sideLength / 2, isL: true },
                    { x: midX - sideLength / 2, y: midY + sideLength / 2, isL: true },
                    { x: midX - sideLength / 2, y: midY - sideLength / 2, isL: true }
                ];
                data.paths = paths;
            }
            else if(data?.type == "path"){

                const normalizeCoordinatesToWeb = (xMobile: any, yMobile: any, webWidth: any, webHeight: any) => {
                    let mobileWidth = JSON.parse(message?.message)?.mobileWidth;
                    let mobileHeight = JSON.parse(message?.message)?.mobileHeight;
                    
                    const xWeb = (xMobile / mobileWidth) * webWidth;
                    const yWeb = (yMobile / mobileHeight) * webHeight;

                    return { x: xWeb, y: yWeb };
                };
                const normalizePathsToWeb = (paths: any) => {
                    return paths?.map((path: any) => {
                        const { x, y, ...rest } = path;
                        const normalized = normalizeCoordinatesToWeb(x, y, webWidth, webHeight);
                        return { ...rest, ...normalized };
                    });
                };
                let paths = await normalizePathsToWeb(data?.paths)
                console.log(paths,"paths")
                data.paths = paths;
            }          
            
            
            if (message?.senderId !== localParticipant?.id) {
                if (data?.isDrawing == true) {
                    setIsScreenDrawingOpen(true);
                } else if(data?.isClear == true) {
                    setIsScreenDrawingOpen(false);
                }
                else if(data?.paths?.length > 0){
                    canvasRef?.current?.loadPaths(data);
                }
            }
        },
        onOldMessagesReceived: (messages) => {
            messages.map((message) => {
                canvasRef?.current?.loadPaths(JSON.parse(message?.message));
            });
        },
    });

    const { publish: toggleScreenDrawingMode } = usePubSub("SCREEN_DRAWING", {
        onMessageReceived: (message) => {
            if (message?.senderId !== localParticipant?.id) {
                if (message?.message === "true") {
                    setIsScreenDrawingOpen(true);
                } else {
                    setIsScreenDrawingOpen(false);
                }
            }
        },
        onOldMessagesReceived: (messages) => {
            messages?.map((message) => {
                setIsScreenDrawingOpen(message.message === "true" ? true : false);
            });
        },
    });
    
    const drawShape = (startX: number, startY: number, endX: number, endY: number) => {
        const canvas = canvasRef.current;
    
        const shape = (shape: any) => {
            switch (shape) {
                case "triangle":
                    return "trianglePath";
                case "square":
                    return "rectanglePath";
                case "arrow":
                    return "arrowLinePath";
                default:
                    return "";
            }
        };
    
        if (!canvas) return;
    
        let paths: any[] = [];
        let path: any[] = [];
        const midX = (startX + endX) / 2;
        const midY = (startY + endY) / 2;
    
        const minLength = 30; // Minimum length for one edge
        const screenheight = window.outerHeight;
        const screenwidth = window.outerWidth;
    
        if (selectedDrawing === 'triangle') {
            let deltaX = Math.abs(endX - startX);
            let deltaY = Math.abs(endY - startY);
            let legLength = Math.min(deltaX, deltaY);
    
            legLength = Math.max(legLength, minLength);
    
            const directionX = endX >= startX ? 1 : -1;
            const directionY = 1;
    
            paths = [
                { x: startX, y: startY, isL: true },
                { x: startX + legLength * directionX, y: startY + legLength, isL: true },
                { x: startX - legLength * directionX, y: startY + legLength, isL: true },
                { x: startX, y: startY, isL: true }
            ];
    
            path = [
                [{ x: startX + legLength * directionX, y: startY + legLength, isL: true, color, scale: 50, webheight: screenheight, webwidth: screenwidth, platform: "web" }]
            ];
        } else if (selectedDrawing === 'square') {
            const distance = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));
            let sideLength = distance / Math.sqrt(2);
    
            sideLength = Math.max(sideLength, minLength);
    
            paths = [
                { x: midX - sideLength / 2, y: midY - sideLength / 2, isL: true },
                { x: midX + sideLength / 2, y: midY - sideLength / 2, isL: true },
                { x: midX + sideLength / 2, y: midY + sideLength / 2, isL: true },
                { x: midX - sideLength / 2, y: midY + sideLength / 2, isL: true },
                { x: midX - sideLength / 2, y: midY - sideLength / 2, isL: true }
            ];
    
            path = [
                [{ x: midX - sideLength / 2, y: midY - sideLength / 2, isL: true, color, scale: 50, webheight: screenheight, webwidth: screenwidth, platform: "web" }]
            ];
        } else if (selectedDrawing === 'arrow') {
            const headLength = 15;
            const dx = endX - startX;
            const dy = endY - startY;
            const angle = Math.atan2(dy, dx);
    
            const lineLength = Math.sqrt(dx * dx + dy * dy);
    
            const unitDx = dx / lineLength;
            const unitDy = dy / lineLength;
    
            const adjustedEndX = endX - unitDx * headLength;
            const adjustedEndY = endY - unitDy * headLength;
    
            const arrowHeadLeft = {
                x: adjustedEndX - headLength * Math.cos(angle - Math.PI / 6),
                y: adjustedEndY - headLength * Math.sin(angle - Math.PI / 6),
                isL: true
            };
            const arrowHeadRight = {
                x: adjustedEndX - headLength * Math.cos(angle + Math.PI / 6),
                y: adjustedEndY - headLength * Math.sin(angle + Math.PI / 6),
                isL: true
            };
    
            paths = [
                { x: startX, y: startY, isL: true },
                { x: adjustedEndX, y: adjustedEndY, isL: true },
                arrowHeadLeft,
                { x: adjustedEndX, y: adjustedEndY, isL: true },
                arrowHeadRight
            ];
    
            path = [
               [ { x: startX, y: startY, isL: true, color, webheight: screenheight, webwidth: screenwidth, platform: "web" },
                { x: endX, y: endY, isL: true, color, webheight: screenheight, webwidth: screenwidth, platform: "web" }],
            ];
        }
    
        if (paths.length > 0) {
            const newPath = {
                paths,
                path,
                strokeColor: color,
                strokeWidth: 4,
                drawMode: true,
                type: shape(selectedDrawing)
            };
    
            canvas?.loadPaths([newPath]);
            console.log("newPath", newPath);
            publish(JSON.stringify(newPath), { persist: true });
        }
    };
    
    const onMouseDownOrTouchStart = (e: any) => {
        if (!isScreenDrawingOpen || selectedDrawing === 'freestyle') return;
        const isTouchEvent = e.type === 'touchstart';
        if (isTouchEvent) {
            const { pageX, pageY } = e.touches[0];
            startCoords.current = { x: pageX, y: pageY };
        } else {
            const { offsetX, offsetY } = e.nativeEvent;
            startCoords.current = { x: offsetX, y: offsetY };
        }
    };

    const onMouseUpOrTouchEnd = (e: any) => {
        if (!isScreenDrawingOpen || selectedDrawing === 'freestyle' || !startCoords.current) return;
        const isTouchEvent = e.type === 'touchend';
        if (isTouchEvent) {
            const { pageX, pageY } = e.changedTouches[0];
            drawShape(startCoords.current.x, startCoords.current.y, pageX, pageY);
            startCoords.current = null;
        } else {
            const { offsetX, offsetY } = e.nativeEvent;
            drawShape(startCoords.current.x, startCoords.current.y, offsetX, offsetY);
            startCoords.current = null;
        }
    };


    const onStroke = (stroke: any, isEraser: boolean) => {
        const screenheight = window.outerHeight;
        const screenwidth = window.outerWidth;
        const updatedStroke = {
            ...stroke, // Keep existing properties
            path: [stroke.paths.map((p: any) => ({
                ...p, // Keep the original path properties
                color: stroke.strokeColor, // Add the color to each path
                platform: "web",
                webheight: screenheight, 
                webwidth: screenwidth,
            }))],
            type:"path"
        };
    
        if (selectedDrawing === 'freestyle') {
            // Pass the updated stroke with color included in paths
            publish(JSON.stringify(updatedStroke), { persist: true });
        }
    };
    
    

    const toggleScreenSharing = (event: any) => {
        if (isScreenSharing) {
            disableScreenShare();
            toast.success("Screen sharing stopped");
        } else {
            enableScreenShare?.();
            toast.success("Screen sharing started");
        }
        setIsScreenSharing((prevState) => !prevState);
    };


    return (
        <div className="container" style={{ width: "100%", height: "100%", display: "flex", flex: 1, backgroundColor: "black" }}>
            {joined && joined === "JOINED" ? (
                <ParticipantContainerStyle>
                    <div className="topMobileNav">
                        <Typography style={{ color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px" }}>{`${coachProfile?.attributes?.first_name} ${coachProfile?.attributes?.last_name}`}</Typography>
                        <Typography style={{ color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px" }}>{formatTime(time)}</Typography>
                    </div>
                    {participantRender[0] &&
                        <ParticipantView isAudio={isAudio} containerStyle={"containerFullScreen"} cameraStyle={"camContainerFullScreen"} key={participantRender[0].id} participantId={participantRender[0].id} />
                    }
                    {participantRender[1] &&
                        <ParticipantView onVideoClick={onVideoSwap} isAudio={isAudio} cameraStyle={participantRender.length > 2 && participantRender[1] ? "camContainerMiniRight" : "camContainerFullScreen"} containerStyle={participantRender[1] ? isAnimationVisible ? "containerMiniLeft" : "containerMiniRight" : "containerFullScreen"} key={participantRender[1].id} participantId={participantRender[1].id} />
                    }
                    <Box
                        sx={webStyles.bottomNavigation}
                    >
                        {isRecording &&
                            (<Paper elevation={3}>
                                <BottomNavigation
                                    style={{ height: "50px", backgroundColor: "#222222", width: "100%", display: "flex", alignItems: "center" }}
                                    data-test-id="recordingBar"
                                >
                                    <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "red", marginRight: "10px" }}></div>
                                    <Typography style={{ width: "85px", textAlign: "center", color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 400, fontSize: "16px" }}>{recordingFormat(recordingTime)}
                                    </Typography>
                                    <img onClick={onClickRecording} src={ic_stop_button} style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "50%", marginLeft: "10px" }} />
                                </BottomNavigation>
                            </Paper>)}
                        <Paper elevation={3}>
                            <BottomNavigation
                                style={{ height: "94px", backgroundColor: "black" }}
                                data-test-id="bottomNavigation"
                            >
                                <BottomBarStyle>
                                    <img src={coachProfile?.attributes?.photo ?? icDefaultAvatar} alt="Coach" style={{ width: "56px", height: "56px", borderRadius: "50%" }} />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography className="participantTitle" style={{ color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px" }}>{`${coachProfile?.attributes?.first_name} ${coachProfile?.attributes?.last_name}`}
                                        </Typography>
                                        <Typography style={{ color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 400, fontSize: "16px" }}>{formatTime(time)}
                                        </Typography>
                                    </div>
                                </BottomBarStyle>
                                <BottomNavigationAction
                                    style={{ maxWidth: "90px" }}
                                    icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>{localWebcamOn ? <VideocamOutlinedIcon htmlColor="#1C1B1F" /> : <VideocamOffOutlinedIcon htmlColor="#1C1B1F80" />}</div>}
                                    onClick={() => toggleWebcam()}
                                    data-test-id="webcam"
                                />

                                <BottomNavigationAction
                                    style={{ maxWidth: "90px" }}
                                    icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>{isAudio ? <VolumeUpOutlined htmlColor="#1C1B1F" /> : <VolumeOffOutlined htmlColor="#1C1B1F80" />}</div>}
                                    onClick={() => { setIsAudio(prestate => !prestate) }}
                                    data-test-id="sound"
                                />

                                <BottomNavigationAction
                                    style={{ maxWidth: "90px" }}
                                    icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>{localMicOn ? <Mic htmlColor="#1C1B1F" /> : <MicOff htmlColor="#1C1B1F80" />}</div>}
                                    onClick={() => toggleMic()}
                                    data-test-id="mic"
                                />
                                <BottomNavigationAction
                                    style={{ maxWidth: "90px" }}
                                    icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "#FA233C" }}>
                                        <CallEnd htmlColor="white" />
                                    </div>}
                                    onClick={() => {
                                        leave();
                                        onMeetingLeave();
                                    }}
                                    data-test-id="confirmLeave"
                                />

                            </BottomNavigation>
                        </Paper>
                    </Box>
                    <BottomRightBarStyle>
                        <BottomNavigationAction
                            style={{ maxWidth: "90px" }}
                            icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>
                                <img src={isRecording ? icScreenRecordingActive : icScreenRecording} style={{ width: "48px", height: "48px" }} />
                            </div>}
                            onClick={onClickRecording}
                        />
                        <BottomNavigationAction
                            data-test-id="screenSharingButton"
                            style={{ maxWidth: "90px" }}
                            icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>
                                <img src={icScreenSharing} style={{ width: "48px", height: "48px" }} />
                            </div>}
                            onClick={toggleScreenSharing}
                        />
                        <BottomNavigationAction
                            data-test-id="screenDrawingButton"
                            style={{ maxWidth: "90px" }}
                            icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>
                                <img src={icScreenDrawing} style={{ width: "48px", height: "48px" }} />
                            </div>}
                            onClick={onToggleScreenDrawing}
                        />
                        <BottomNavigationAction
                            style={{ maxWidth: "90px" }}
                            icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>
                                <img src={icOverlay} style={{ width: "48px", height: "48px" }} />
                            </div>}
                            data-test-id="menuBtn"
                            onClick={(event) => {
                                anchorEl.current = event?.currentTarget;
                                setIsOverlayOpen(true);
                            }} />
                        <Menu
                            style={{
                                marginLeft: "0px",
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: {
                                    width: '181px',
                                    backgroundColor: "#4A4A4A",
                                    color: "#FFFFFF",
                                    marginTop: "120px"
                                },
                            }}
                            anchorEl={anchorEl.current}
                            open={isOverlayVisible}
                            onClose={() => { setIsOverlayOpen(false); setIsTilesSelected(false) }}
                        >
                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>

                                {isTilesSelected ?
                                    [
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            justifyContent: "center",
                                        }}
                                        >Tiles
                                            <img src={ic_down} style={{ width: "12px", height: "7px", marginLeft: "10px" }} />
                                        </MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            fontSize: "14px",
                                            justifyContent: "center",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Attack Angle") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            data-test-id="attackAngle"
                                            onClick={onSelectTile({ title: "Attack Angle", unit: "DEG", value: "0.9" })}
                                        >Attack Angle</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Ball Speed") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Ball Speed", unit: "MPH", value: "159.4" })}
                                        >Ball Speed</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Carry") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Carry", unit: "YARD", value: "255.8" })}
                                        >Carry</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Club Path") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Club Path", unit: "DEG", value: "3.5" })}
                                        >Club Path</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Club Speed") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Club Speed", unit: "MPH", value: "107.7" })}
                                        >Club Speed</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Curve") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Curve", unit: "DEG", value: "-1.4" })}
                                        >Curve</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Swing Dir") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Swing Dir", unit: "DEG", value: "4.4" })}
                                        >Swing Dir</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Swing PI") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Swing PI", unit: "DEG", value: "44.6" })}
                                        >Swing PI</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Dyn Loft") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Dyn Loft", unit: "DEG", value: "12.5" })}
                                        >Dyn Loft</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Spin Loft") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Spin Loft", unit: "DEG", value: "11.6" })}
                                        >Spin Loft</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Face Ang") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Face Ang", unit: "DEG", value: "2.8" })}
                                        >Face Ang</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Face to Path") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Face to Path", unit: "DEG", value: "0.7" })}
                                        >Face to Path</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Spin Axe") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Spin Axe", unit: "DEG", value: "-1.4" })}
                                        >Spin Axe</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Smash Fac") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Smash Fac", unit: "", value: "1.48" })}
                                        >Smash Fac</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Total") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Total", unit: "YARD", value: "277.7" })}
                                        >Total</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Height") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Height", unit: "FT", value: "88.2" })}
                                        >Height</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Launch Ang") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Launch Ang", unit: "DEG", value: "10.6" })}
                                        >Launch Ang</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Launch Dir") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Launch Dir", unit: "DEG", value: "2.9" })}
                                        >Launch Dir</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Spin Rate") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Spin Rate", unit: "RPM", value: "2957" })}
                                        >Spin Rate</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Land. Ang") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Land. Ang", unit: "DEG", value: "37.6" })}
                                        >Land. Ang</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center",
                                            backgroundColor: selectedTiles.some((selectedTile: any) => selectedTile.title === "Spin Tot") ? "#2F7735" : "#4A4A4A",
                                        }}
                                            onClick={onSelectTile({ title: "Spin Tot", unit: "R", value: "3.4" })}
                                        >Spin Tot</MenuItem>
                                    ]
                                    : [
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center"
                                        }}
                                        >Select an item
                                            <img src={ic_down} style={{ width: "12px", height: "7px", marginLeft: "10px" }} />
                                        </MenuItem>,

                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center"
                                        }}
                                            data-test-id="tilesButton"
                                            onClick={() => setIsTilesSelected(true)}
                                        >Tiles</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center"
                                        }}
                                        >Video</MenuItem>,
                                        <MenuItem style={{
                                            fontFamily: "Urbanist, sans-serif",
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "center",
                                            fontSize: "14px",
                                            height: "63px",
                                            textAlign: "center"
                                        }}
                                            data-test-id="animationBtn"
                                            onClick={() => {
                                                setIsScreenDrawingOpen(false);
                                                setSelectedTiles([]);
                                                setIsAnimationVisible(prestate => !prestate)
                                                toggleScreenDrawingMode(JSON.stringify(false), { persist: true });
                                            }}
                                        >Animation</MenuItem>,
                                    ]
                                }
                            </div>
                        </Menu>
                    </BottomRightBarStyle>
                    {isAnimationVisible &&
                        <div className="animationContainer">
                            <img src={ic_animation} />
                        </div>}
                    {isScreenDrawingOpen &&
                        (
                            <>
                                <div className="screenDrawingContainer">
                                    <img data-test-id="triangleButton" src={ic_triangle} className={`drawBtn ${selectedDrawing === 'triangle' && "selected"}`} onClick={() => setSelectedDrawing('triangle')} />
                                    <img data-test-id="arrowButton" src={ic_arrow} className={`drawBtn ${selectedDrawing === 'arrow' && "selected"}`} onClick={() => setSelectedDrawing('arrow')} />
                                    <img data-test-id="freestyleButton" src={ic_freestyle} className={`drawBtn ${selectedDrawing === 'freestyle' && "selected"}`} onClick={() => setSelectedDrawing('freestyle')} />
                                    <img data-test-id="squareButton" src={ic_square} className={`drawBtn ${selectedDrawing === 'square' && "selected"}`} onClick={() => setSelectedDrawing('square')} />
                                </div>
                                <div className="colorSliderContainer">
                                    <HexColorPicker className="colorSlider" color={color} onChange={setColor} />
                                </div>
                            </>
                        )}
                    {selectedTiles.length > 0 &&
                        <div className="tiles">
                            {selectedTiles.map((tile: any) => {
                                return (<div className="tile">
                                    <Typography className="title">{tile.title}</Typography>
                                    <Typography className="value">{tile.value}</Typography>
                                    <Typography className="unit">{tile.unit}</Typography>
                                </div>)
                            }
                            )}
                        </div>
                    }

                    {isScreenDrawingOpen && (<div
                        data-test-id="drawingCanvas"
                        className={`drawingCanvas ${isScreenDrawingOpen ? "drawingCanvasEnabled" : ""}`}
                        onMouseDown={onMouseDownOrTouchStart}
                        onMouseUp={onMouseUpOrTouchEnd}
                        onTouchStart={onMouseDownOrTouchStart}
                        onTouchEnd={onMouseUpOrTouchEnd}
                    >
                        <ReactSketchCanvas
                            ref={canvasRef}
                            onStroke={onStroke}
                            {...canvasProps}
                        />
                    </div>)}
                </ParticipantContainerStyle>
            ) :
                <>
                    <p style={{ color: "white" }}>Joining the meeting...</p>
                    <button ref={joinBtnRef} style={{ opacity: 0 }} onClick={joinMeeting}>Join</button>
                </>
            }
        </div>
    );
}

function CustomVideoCall({ meetingId, authToken, coachProfile, onLeave }: any) {
    return authToken && meetingId ? (
        <MeetingProvider
            config={{
                meetingId,
                micEnabled: true,
                webcamEnabled: true,
                name: "participant" + Math.random(),
            }}
            token={authToken}
        >
            <MeetingConsumer>
                {() => <MeetingView authToken={authToken} meetingId={meetingId} coachProfile={coachProfile} onMeetingLeave={onLeave} />}
            </MeetingConsumer>
        </MeetingProvider>
    ) : <div />
}

const webStyles = {
    bottomBtn: {
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    meetingId: {
        color: "#706694",
    },
    container: {
        display: "flex",
        flex: 1,
    },
    participantsContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flex: 1,
        overflowY: "scroll",
    },
    bottomNavigation: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        overflow: "hidden",
        zIndex: 10,
    },
};

export default CustomVideoCall;
