import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;

}

interface S {
    password: string;
    email: string;
    enablePasswordField: boolean;
    checkedRememberMe: boolean;
    placeHolderEmail: string;
    placeHolderPassword: string;
    imgPasswordVisible: any;
    imgPasswordInVisible: any;
    labelHeader: string;
    btnTxtLogin: string;
    labelRememberMe: string;
    btnTxtSocialLogin: string;
    labelOr: string;
    emailError: string;
    passwordError: string;
}

interface SS {
    id: any;
}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiEmailLoginCallId: string = "";
    validationApiCallId: string = "";
    // emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            email: "",
            password: "",
            enablePasswordField: true,
            checkedRememberMe: false,
            placeHolderEmail: configJSON.placeHolderEmail,
            placeHolderPassword: configJSON.placeHolderPassword,
            imgPasswordVisible: configJSON.imgPasswordVisible,
            imgPasswordInVisible: imgPasswordInVisible,
            labelHeader: configJSON.labelHeader,
            btnTxtLogin: configJSON.btnTxtLogin,
            labelRememberMe: configJSON.labelRememberMe,
            btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
            labelOr: configJSON.labelOr,
            emailError: "",
            passwordError: "",
        };
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.callGetValidationApi();
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    }

    // Custom validation method for email
    validateEmail(email: string) {
        console.log("email", email);
        if (!email) {
            this.setState({ emailError: "Email is required" });
            return false;
        }
        else {
            this.setState({ emailError: "" });
            return true;
        }
    }

    // Custom validation method for password
    validatePassword(password: string) {
        console.log("password", password);
        const errorMessage = "Password is required";
        if (!password) {
            this.setState({ passwordError: errorMessage });
            return false;
        }
        else {
            this.setState({ passwordError: "" });
            return true;
        }
        // return isValid;
    }

    // Handle login button press
    EmailAccountLogIn() {
        const isValidEmail = this.validateEmail(this.state.email);
        const isValidPassword = this.validatePassword(this.state.password);

        if (isValidEmail && isValidPassword) {
            this.doEmailLogIn();
        } else {
            return false;
        }
    }

    redirectToRegistration = (event: any) => {
        event.preventDefault();
        this.props.navigation.navigate("EmailAccountRegistration");
    }

    // Web Event Handling
    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };


    // Handle email input change
    setEmail = (text: string) => {
        this.setState({
            email: text,
        });
        // Validate email input on change
        this.validateEmail(text);
    };

    // Handle password input change
    setPassword = (text: string) => {
        this.setState({
            password: text,
        });
        // Validate password input on change
        this.validatePassword(text);
    };


    setRememberMe = (value: boolean) => {
        this.setState({ checkedRememberMe: value });
    };

    CustomCheckBoxProps = {
        onChangeValue: (value: boolean) => {
            this.setState({ checkedRememberMe: value });
            this.CustomCheckBoxProps.isChecked = value;
        },
        isChecked: false,
    };

    btnForgotPasswordProps = {
        onPress: () => this.goToForgotPassword(),
    };

    txtInputPasswordProps = {
        onChangeText: (text: string) => {
            this.setState({ password: text });

            //@ts-ignore
            this.txtInputPasswordProps.value = text;
        },
        secureTextEntry: true,
    };

    btnPasswordShowHideImageProps = {
        source: imgPasswordVisible,
    };

    txtInputEmailWebProps = {
        onChangeText: (text: string) => {
            this.setState({ email: text });

            //@ts-ignore
            this.txtInputEmailProps.value = text;
        },
    };

    txtInputEmailMobileProps = {
        ...this.txtInputEmailWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputEmailProps = this.isPlatformWeb()
        ? this.txtInputEmailWebProps
        : this.txtInputEmailMobileProps;

    async receive(from: string, message: Message) {
        switch (message.id) {
            case getName(MessageEnum.ReciveUserCredentials):
                this.handleUserCredentialsMessage(message);
                break;
            case getName(MessageEnum.RestAPIResponceMessage):
                this.handleAPIResponseMessage(message);
                break;
        }
    }


    handleUserCredentialsMessage(message: Message) {
        const userName = message.getData(getName(MessageEnum.LoginUserName));
        const password = message.getData(getName(MessageEnum.LoginPassword));
        const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));

        if (!countryCode && userName && password) {
            this.setState({
                email: userName,
                password: password,
                checkedRememberMe: true,
            });
            //@ts-ignore
            this.txtInputEmailProps.value = userName;
            //@ts-ignore
            this.txtInputPasswordProps.value = password;
            this.CustomCheckBoxProps.isChecked = true;

        }
    }

    handleAPIResponseMessage(message: Message) {

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))

        if (apiRequestCallId === this.apiEmailLoginCallId) {
            this.handleEmailLoginResponse(responseJson, errorResponse);
        }
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.apiEmailLoginCallId) {
                // this.props.navigation.navigate("CustomForm")
            }

        }
    }



    handleEmailLoginResponse(responseJson: any, errorResponse: any) {
        if (responseJson?.errors) {
            toast.error(responseJson?.errors);
            return;
        }

        if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            // this.openInfoPage();
            toast.success("Login Successfully!");
            if (responseJson.meta.role_id === "instructor") {
                this.props.navigation.navigate('MyBookings');
            } else {
                this.props.navigation.navigate('Home');
            }
        } else {
            this.sendLoginFailMessage();
            toast.error(responseJson?.errors[0]?.failed_login);
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }


    sendLoginFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    sendLoginSuccessMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

        msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
        msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
        msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
        msg.addData(
            getName(MessageEnum.LoginIsRememberMe),
            this.state.checkedRememberMe
        );

        this.send(msg);
    }

    saveLoggedInUserData(responseJson: any) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );
            msg.addData(
                getName(MessageEnum.SessionResponseToken),
                responseJson.meta.token
            );

            this.send(msg);
        }
    }

    openInfoPage() {
        // Merge Engine - Navigation - btnEmailLogIn - Start
        const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
        // Merge Engine - Navigation - btnEmailLogIn - End
    }

    goToForgotPassword() {
        // Merge Engine - Navigation - btnForgotPassword - Start
        const msg: Message = new Message(
            getName(MessageEnum.NavigationForgotPasswordMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
        this.send(msg);
        // Merge Engine - Navigation - btnForgotPassword - End
    }

    forgotPasswordHandler() {
        this.props.navigation.navigate("ForgotPasswordOTP");
    }

    goToSocialLogin() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationSocialLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    doEmailLogIn(): boolean {

        const header = {
            "Content-Type": configJSON.loginApiContentType,
        };

        const attrs = {
            email: this.state.email,
            password: this.state.password,
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEmailLoginCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    callGetValidationApi() {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validationApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.urlGetValidations
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
}
