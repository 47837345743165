import React from "react";

import {
    Box,
    Typography,
    Grid,
    Checkbox,
    Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { searchIcon, filterIcon, icDefaultAvatar, icStar } from './assets'
import {
    Props,
} from "./EmailAccountLoginController.web";
import HomeLayout from "../../../components/src/HomeLayout";
import HomepageController from "./HomepageController.web";
import { Stack } from "@mui/material";
import { DrawerStyle } from "./Homepage.web";
import Slider from '@mui/material/Slider';
import { grayStar, orangeStar } from "../../../blocks/reviews/src/assets";

const NewInstructorsStyle = styled("div")({
    fontFamily: "Urbanist, sans-serif",

    "& .sideBarContainer": {
        display: 'flex',
        gap: '10px',
        flexDirection: 'row',
    },

    "& .ratingContainer": {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        gap: "3px",
        marginBottom: "2px",
    },

    "& .instructorDes": {
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
    },

    "& .findInstructorBox": {
        borderRadius: '8px',
        backgroundColor: "#F7F8F9",
        border: '1px solid #E8ECF4',
        marginLeft: 0,
        marginRight: 0,
        flexDirection: 'row',
        padding: "15px",
        display: "flex",
        alignItems: "center",
        margin: "10px",
    },

    "& .contentContainer": {
        paddingInline: '100px',

        "@media (max-width: 960px)": {
            paddingInline: '50px',
        },

        "@media (max-width: 600px)": {
            paddingInline: '30px',
        },
    },

    "& .filterText": {
        marginLeft: "10px",

        "@media (max-width: 600px)": {
            display: "none"
        },
    },

    "& .searchInput": {
        border: 'none',
        backgroundColor: "#F7F8F9",
        color: "#8391A1",
        width: "250px",

        "@media (max-width: 500px)": {
            width: "120px",
        },

        "@media (max-width: 380px)": {
            width: "100px",
        },

        "&:focus": {
            outline: 'none',
            border: 'none',
        },
    }
})

const TopViewableComponent = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    paddingInline: '100px',
    marginTop: "20px",
    marginBottom: "10px",
    justifyContent: 'space-between',
    alignItems: 'center',


    "@media (max-width: 960px)": {
        paddingInline: '50px',
    },

    "@media (max-width: 600px)": {
        paddingInline: '30px',
    },

    "& h1": {
        fontWeight: 700,
        fontSize: "24px"
    },
})

export default class NewInstructors extends HomepageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const StarRating = ({ rating, size = "39px" }: { rating: number, size?: string }) => {
            const roundedRatingFav = Math.round(rating);
            const fullStarsFav = roundedRatingFav;
            const emptyStarsFav = 5 - fullStarsFav;
            const starsFav = [];
          
            for (let i = 0; i < emptyStarsFav; i++) {
                starsFav.push(<img key={fullStarsFav + i} src={grayStar} style={{width: size, height: size, cursor:"pointer"}} alt="gray-star" />);
              }

            for (let i = 0; i < fullStarsFav; i++) {
              starsFav.push(<img key={i} src={orangeStar} style={{width: size, height: size, cursor:"pointer"}} alt="orange-star" />);
            }
          
            return <div>{starsFav}</div>;
          }

          this.testStarRating = StarRating;

        return (
            <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
                <NewInstructorsStyle>
                    <TopViewableComponent>
                        <Typography variant="h1">
                            New Instructors
                        </Typography>
                        <div className="sideBarContainer">
                            <Box
                                sx={{
                                    display: "flex",
                                    bgcolor: '#F7F8F9',
                                    flexDirection: "row",
                                    alignItems: "center",
                                    borderRadius: '6px',
                                    paddingX: '10px',
                                    paddingY: '5px',
                                    border: "1px solid #E8ECF4",
                                }}
                            >
                                <img src={searchIcon} style={{ height: '18px', marginRight: '10px', width: '16px', }} />
                                <input
                                    className="searchInput"
                                    data-test-id={"inputSearchText"}
                                    placeholder="Search"
                                    value={this.state.newInstructorSearchText}
                                    onChange={(event: any) => this.setState({newInstructorSearchText: event.target.value})}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingX: '10px',
                                    paddingY: '5px',
                                    width: "min-content",
                                    alignItems: "center",
                                    border: "1px solid #2F7735",
                                    borderRadius: '6px',
                                }}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.onDrawerNewOpen()}
                                data-test-id="filterBtn"
                                >
                                <img src={filterIcon} style={{ width: '18px', height: '18px' }} />
                                <Typography className="filterText" variant="subtitle1" style={{ color: "#2F7735" }}>Filter</Typography>
                            </Box>
                        </div>
                    </TopViewableComponent>
                    <Grid container className="contentContainer">
                        <Grid xs={12} container spacing={1}>
                            {this.state.newInstructors && this.state.newInstructors.filter((item: any) => `${item.attributes.first_name} ${item.attributes.last_name}`.toLowerCase().includes(this.state.newInstructorSearchText.toLowerCase())).length === 0 && 
                             <Typography variant="subtitle2" style={{width: "100%", justifyContent:"center", display: "flex"}}>
                             No New Instructor Found!
                           </Typography>}
                            {(this.testNewInstructor || this.state.newInstructors)?.filter((item: any) => `${item.attributes.first_name} ${item.attributes.last_name}`.toLowerCase().includes(this.state.newInstructorSearchText.toLowerCase())).map((instructor: any, index: number) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} onClick={this.handleProfileClick(instructor)}>
                                        <Box className="findInstructorBox">
                                            <img src={instructor?.attributes?.photo ?? icDefaultAvatar} style={{ marginRight: "10px", borderRadius: "40px", width: "80px", height: "80px" }} />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Typography variant="subtitle2">
                                                    {`${instructor?.attributes?.first_name} ${instructor?.attributes?.last_name}`}
                                                </Typography>
                                                <div className="ratingContainer" style={{ justifyContent: "flex-start" }}>
                                                    <img src={icStar} style={{ width: "14px" }} />
                                                    <Typography style={{ fontSize: "12px", fontWeight: 500, color: "#666666", }} variant="subtitle2">
                                                        {`${instructor?.attributes?.average_rating?.toFixed(1)} (${instructor?.attributes?.total_number_of_review})`}
                                                    </Typography>
                                                </div>
                                                <Typography style={{ fontSize: "11px", fontWeight: 500, color: "#666666" }} variant="body1" className="instructorDes">
                                                    {instructor?.attributes?.describe_yourself}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Grid>)
                            })}
                        </Grid>
                    </Grid>
                    <DrawerStyle
          onClose={this.onDrawerNewClose}
          anchor="right"
          open={this.state.isNewFilterOpen}
          PaperProps={{ style: { background: 'white' }, className: "drawerContainer" }}
        >
          <Box sx={{ mb: 2, flex:1, display: "flex", }}>
            <ul
              style={{
                flex: 1,
                justifyContent: "center",
                paddingInline: "35px",
                gap: "20px",
                listStyleType: "none",
                flexDirection: "column",
                fontFamily: "Urbanist, sans-serif",
                display: "flex",
              }}
            >
              <Typography variant="h2" style={{fontSize: "32px", fontWeight: 700, marginTop: "25px", fontFamily: "inherit",}}>
                Filter
              </Typography>
                <div style={{ flexDirection: 'column', display: 'flex', position: 'relative', width: '96%' }} className="inputField">
                  <label className="labelStyle" style={{ marginTop: "5px", fontWeight: 700, fontSize: "18px", marginBottom: "5px", color: "#333333"}}>
                    Rating
                  </label>
                  {Array.from({ length: 5 }, (x, index) => index).map((number, index) =>
                  (<div style={{ display: "flex", gap: "10px", flexDirection: "row", marginTop: "10px", alignItems: "center" }}>
                    <Checkbox
                      checked={this.state.rate ? this.state.rate === 5 - number : false}
                      onChange={() => this.setRate(5 - number)}
                      data-test-id={`checkbox${index}`}
                      style={{ padding: '0px', color: '#2F7735'}}
                    />
                    <StarRating rating={5 - number} />
                    {index !== 0 &&
                      <Typography variant="caption" style={{ fontWeight: 500, color: "#333333", fontFamily: "inherit", fontSize: "16px", }}>
                        & above
                      </Typography>
                    }
                  </div>)
                  )}
                 
                </div>

                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', width: '96%', position: 'relative', }}>
                  <label className="labelStyle" style={{ marginTop: "5px", marginBottom: "5px", fontWeight: 700, color: "#333333", fontSize: "18px", }}>
                    Price
                  </label>

                  <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <label>$0</label>
                    <Slider className="customSlider" data-test-id="customSlider" valueLabelFormat={(value) => `$${value}`} value={this.state.price} onChange={(event: any) => this.setPrice(+event?.target?.value)} min={0} max={99} defaultValue={0} valueLabelDisplay="auto" aria-label="Default" color="secondary" />
                    <label>$99</label>
                  </Stack>                 
                </div>

                <div className="inputField" style={{ flexDirection: 'column', display: 'flex', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", fontWeight: 700, marginTop: "5px", fontSize: "18px", color: "#333333"}}>
                  Location
                  </label>

                  <input
                  data-test-id="txtInputLocation"
                  className="textField"
                  placeholder={"Location"}
                  value={this.state.location}
                  type="text"
                  onChange={(e) => this.setLocation(e.target.value)}
                />          
                </div>

                <div style={{width: "100%", flex:1, paddingBottom: "20px", display: "flex", gap: "10px", alignItems: "flex-end", flexDirection: "row", justifyContent:"space-between"}}>
                  <Button data-test-id="clearBtn" className="clearBtn" onClick={() => this.onResetFilter()}>Clear</Button>
                  <Button data-test-id="submitBtn" className="submitBtn" onClick={() => this.onNewFilter()}>Filter</Button>
                </div>
            </ul>
          </Box>
        </DrawerStyle>
                </NewInstructorsStyle>
            </HomeLayout>
        );
    }
}
